var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
      [
        _c(
          "q-chip",
          {
            staticStyle: { "margin-bottom": "4px !important" },
            attrs: { color: "blue", "text-color": "white" },
          },
          [_vm._v(" 물리적 위험성 ")]
        ),
        _vm._l(_vm.list3, function (item, index) {
          return _c(
            "c-card",
            {
              key: index,
              staticClass: "cardClassDetailForm",
              attrs: {
                title:
                  item.sourceName +
                  (item.sourceResult !== "" ? " : " + item.sourceResult : ""),
                topClass: "topcolor-blue",
                collapsed: true,
              },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-12 ghsinfodetail" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        rows: 3,
                        label: "",
                        name: "ghsValue3String",
                      },
                      model: {
                        value: item.sourceText,
                        callback: function ($$v) {
                          _vm.$set(item, "sourceText", $$v)
                        },
                        expression: "item.sourceText",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
      [
        _c(
          "q-chip",
          {
            staticStyle: { "margin-bottom": "4px !important" },
            attrs: {
              color: "orange",
              outline: "",
              square: "",
              "text-color": "white",
            },
          },
          [_vm._v(" 건강 유해성 ")]
        ),
        _vm._l(_vm.list1, function (item, index) {
          return _c(
            "c-card",
            {
              key: index,
              staticClass: "cardClassDetailForm",
              attrs: {
                title:
                  item.sourceName +
                  (item.sourceResult !== "" ? " : " + item.sourceResult : ""),
                topClass: "topcolor-orange",
                collapsed: true,
              },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-12 ghsinfodetail" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        rows: 3,
                        label: "",
                        name: "ghsValue3String",
                      },
                      model: {
                        value: item.sourceText,
                        callback: function ($$v) {
                          _vm.$set(item, "sourceText", $$v)
                        },
                        expression: "item.sourceText",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
      [
        _c(
          "q-chip",
          {
            staticStyle: { "margin-bottom": "4px !important" },
            attrs: {
              color: "green",
              outline: "",
              square: "",
              "text-color": "white",
            },
          },
          [_vm._v(" 환경 유해성 ")]
        ),
        _vm._l(_vm.list2, function (item, index) {
          return _c(
            "c-card",
            {
              key: index,
              staticClass: "cardClassDetailForm",
              attrs: {
                title:
                  item.sourceName +
                  (item.sourceResult !== "" ? " : " + item.sourceResult : ""),
                topClass: "topcolor-green",
                collapsed: true,
              },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-12 ghsinfodetail" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        rows: 3,
                        label: "",
                        name: "ghsValue3String",
                      },
                      model: {
                        value: item.sourceText,
                        callback: function ($$v) {
                          _vm.$set(item, "sourceText", $$v)
                        },
                        expression: "item.sourceText",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }