<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <q-chip
        color="blue"
        text-color="white"
        style="margin-bottom:4px !important;">
        물리적 위험성
      </q-chip>
      <c-card 
        v-for="(item, index) in list3"
        :key="index"
        :title="item.sourceName + (item.sourceResult !== '' ? ' : ' + item.sourceResult : '')" 
        class="cardClassDetailForm" 
        topClass="topcolor-blue" 
        :collapsed="true">
        <template slot="card-detail">
          <div class="col-12 ghsinfodetail">
            <c-textarea
              :editable="editable"
              :rows="3"
              label=""
              name="ghsValue3String"
              v-model="item.sourceText">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <q-chip
        color="orange"
        outline square
        text-color="white"
        style="margin-bottom:4px !important;">
        건강 유해성
      </q-chip>
      <c-card 
        v-for="(item, index) in list1"
        :key="index"
        :title="item.sourceName + (item.sourceResult !== '' ? ' : ' + item.sourceResult : '')" 
        class="cardClassDetailForm" 
        topClass="topcolor-orange" 
        :collapsed="true">
        <template slot="card-detail">
          <div class="col-12 ghsinfodetail">
            <c-textarea
              :editable="editable"
              :rows="3"
              label=""
              name="ghsValue3String"
              v-model="item.sourceText">
            </c-textarea>
            <!-- <div v-html="item.sourceText"/> -->
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <q-chip
        color="green"
        outline square
        text-color="white"
        style="margin-bottom:4px !important;">
        환경 유해성
      </q-chip>
      <c-card 
        v-for="(item, index) in list2"
        :key="index"
        :title="item.sourceName + (item.sourceResult !== '' ? ' : ' + item.sourceResult : '')" 
        class="cardClassDetailForm" 
        topClass="topcolor-green" 
        :collapsed="true">
        <template slot="card-detail">
          <div class="col-12 ghsinfodetail">
            <c-textarea
              :editable="editable"
              :rows="3"
              label=""
              name="ghsValue3String"
              v-model="item.sourceText">
            </c-textarea>
            <!-- <div v-html="item.sourceText"/> -->
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-ghs-3',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantCd: '',
        plantName: '',
        materialCd: '',
        materialName: '',
        productCategoryCd: '',
        isSaved: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      listUrl: '',
      list1: [],
      list2: [],
      list3: [],
      editable: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.ghs.db.list;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.list1 = this.$_.filter(_result.data, {sourceType : '건강 유해성' });
        this.list2 = this.$_.filter(_result.data, {sourceType : '환경 유해성' });
        this.list3 = [
          {
            sourceName: "폭발성 물질",
            sourceResult: "",
            sourceText: " - 유해위험문구 : 불안정한 폭발성 물질(H200)",
          },
          {
            sourceName: "인화성 가스",
            sourceResult: "",
            sourceText: " - 극인화성 가스(H220)",
          },
          {
            sourceName: "산화성 가스",
            sourceResult: "",
            sourceText: " - 화재를 일으키거나 강렬하게 함: 산화제(H270)",
          },
        ];
      },);
    },
  }
};
</script>

<style lang="sass">
  .ghsinfodetail
    padding-bottom:10px !important
    margin-top:-20px
</style>